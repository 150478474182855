












import {Component, VModel, Vue} from 'vue-property-decorator';
import TimeSchedule from '@/models/TimeSchedule';
import {getAllWeekDaysByNumber, WeekDays} from '@/misc/Enums/WeekDay.ts';

@Component({
  components: {},
})
export default class WorkingTimeComponent extends Vue {

  @VModel({default: () => new TimeSchedule()})
  public timeSchedule!: TimeSchedule;

  private WeekDays() {
    return WeekDays;
  }

  public mounted() {
    if (this.timeSchedule.weekdays.length === 0) {
      this.timeSchedule.weekdays = getAllWeekDaysByNumber();
    }
  }

  public selectWeekDay(day: number) {
    const index = this.timeSchedule.weekdays.indexOf(day);
    if (index === -1) {
      this.timeSchedule.weekdays.push(day);
    } else {
      this.timeSchedule.weekdays.splice(index, 1);
    }
  }

}
